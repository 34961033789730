<!--
 * @Author: 智客云网络科技
 * @Date: 2021-10-16 16:30:40
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-12 18:44:17
 * @Description: 角色管理
 * @FilePath: \src\views\Frame\Auth\Role\RoleManage.vue
-->

<template>
  <div class="vue-box">
    <!-- ------------- 检索参数 ------------- -->
    <div class="table-search-box">
      <div class="table-search-title">检索</div>
      <div class="table-search-content">
        <div class="table-search-item">
          <label>组织筛选：</label>
          <organization-picker v-model="p.kor_id"></organization-picker>
        </div>

        <div class="table-search-btn">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="
              p.pageNo = 1;
              _Load();
            "
            >查询</el-button
          >
        </div>
      </div>
    </div>

    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="success"
        icon="el-icon-plus"
        @click="_OpenNewRoleModel()"
        >创建新角色</el-button
      >
      <el-button
        size="small"
        type="warning"
        icon="el-icon-refresh"
        @click="_Load()"
        >重载数据</el-button
      >
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      :data="TableData"
      size="mini"
      stripe
      border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      row-key="krole_id"
    >
      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="50px"
      ></el-table-column>

      <!-- 角色名称 -->
      <el-table-column
        label="角色名称"
        prop="name"
        align="center"
        show-overflow-tooltip
        width="100px"
      ></el-table-column>

      <!-- 角色状态 -->
      <el-table-column label="状态" align="center" width="80px">
        <template slot-scope="s">
          <el-tag
            size="small"
            effect="plain"
            type="success"
            v-if="s.row.status == 1"
            >启用</el-tag
          >
          <el-tag size="small" effect="plain" type="danger" v-else>禁用</el-tag>
        </template>
      </el-table-column>

      <!-- 权限表 -->
      <el-table-column label="权限表" prop="kright_name" width="600px">
      </el-table-column>

      <!-- 描述 -->
      <el-table-column label="描述" prop="notes" show-overflow-tooltip>
      </el-table-column>

      <!-- 创建日期 -->
      <el-table-column label="创建日期" align="center" width="135px">
        <template slot-scope="s"
          ><span>{{ s.row.add_time }}</span></template
        >
      </el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" width="200px">
        <template slot-scope="s">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="_OpenUpdateRoleModel(s.row.krole_id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="_DelRole(s.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[10, 50, 100, 150]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>

    <!-- 增改组件 -->
    <nu-role ref="nu-role"></nu-role>
  </div>
</template>

<script>
import { getRoleTable, delRole } from "../api"; //页面专有接口
import NuRole from "./NURole";
import OrganizationPicker from "@/components/organizationPicker";
export default {
  name: "RoleManage", //对应路由地址
  components: {
    NuRole,
    OrganizationPicker,
  },
  data() {
    return {
      p: {
        kor_id: "", //组织ID
        pageNo: 1, //当前页
        pageSize: 10, //分页行数
        dataCount: 0, //数据总行数
      },
      TableData: [], //数据集合
    };
  },
  watch: {
    //监听组织ID变动刷新表格
    "p.kor_id": {
      handler() {
        this._Load();
      },
    },
  },
  methods: {
    //载入数据
    _Load() {
      getRoleTable({
        pageNo: this.p.pageNo,
        pageSize: this.p.pageSize,
        kor_id: this.p.kor_id,
      })
        .then((res) => {
          this.TableData = res.data.data; //重写数据
          this.p.dataCount = res.data.dataCount; //重写数据行数
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 103048) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //打开角色创建组件
    _OpenNewRoleModel() {
      this.$refs["nu-role"].open(0, this.p.kor_id);
    },

    //打开角色修改组件
    _OpenUpdateRoleModel(id) {
      this.$refs["nu-role"].open(id);
    },

    /**
     * 删除角色
     */
    _DelRole(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除角色【" +
          data.name +
          '】吗？</br><b style="color:red">此操作将立即生效并且不可撤销!</b>',
        () => {
          delRole({
            krole_id: data.krole_id,
          })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },
  },
};
</script>
