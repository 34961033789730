<!--
 * @Author: 智客云网络科技
 * @Date: 2021-10-16 21:02:43
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-13 00:27:25
 * @Description: 创建 修改 角色
 * @FilePath: \src\views\Frame\Auth\Role\NURole.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="1200px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="100px">
          <el-form-item label="角色名称：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="显示排序：" style="width: 45%">
            <el-input-number
              v-model="m.sort"
              size="small"
              :min="1"
              :max="999999"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="角色状态：" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <div class="from-rightselect-box">
            <el-form-item label="权限分配：">
              <el-card shadow="never">
                <el-tree
                  ref="tree"
                  show-checkbox
                  :data="allRightList"
                  :props="{ children: 'children', label: 'name' }"
                  node-key="right_id"
                  :render-content="renderContent"
                  @node-expand="handleExpand"
                  :highlight-current="true"
                  :default-checked-keys="rightSelectList"
                >
                </el-tree>
              </el-card>
            </el-form-item>
          </div>

          <div class="from-notes-box">
            <el-form-item label="描述：" style="width: 90%; display: block">
              <el-input
                size="small"
                v-model="m.notes"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>

        <el-divider></el-divider>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateNewRole()"
          v-if="id == 0"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateRole()"
          v-else
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>
  </el-dialog>
</template>
<script>
import { createNewRole, updateRole, getRoleInfo, getRightTable } from "../api"; //页面专有接口
export default {
  data() {
    return {
      m: {
        name: "",
        sort: 1,
        right_list: "",
        status: 1,
        notes: "",
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      kor_id: null, //组织ID
      allRightList: [], //所有权限列表
      rightSelectList: [], //选中的权限
    };
  },
  methods: {
    //打开组件
    open(id, kor_id = null) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this.kor_id = kor_id; //组织ID
      this._GetAllRightList(); //加载权限列表
      this.$nextTick(() => {
        if (id === 0) {
          //0 新建
          this.title = "新建角色";
          this.loading = false;
        } else {
          //修改
          this.title = "修改角色";
          this._GetRoleInfo(id);
        }
      });
    },

    //创建新角色
    _CreateNewRole() {
      var m = this.m;
      m.right_list = ""; //重置权限列表
      this.$refs.tree.getCheckedKeys().forEach(function (id) {
        m.right_list += id + ",";
      });
      createNewRole({
        kor_id: this.kor_id, //组织ID
        name: m.name, //名称
        sort: m.sort, //排序
        right_list: m.right_list, //权限列表
        status: m.status, //状态
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改角色
    _UpdateRole() {
      var m = this.m;
      m.right_list = ""; //重置权限列表
      this.$refs.tree.getCheckedKeys().forEach(function (id) {
        m.right_list += id + ",";
      });
      updateRole({
        ko_id: this.id, //角色ID
        name: m.name, //角色名称
        sort: m.sort, //排序
        right_list: m.right_list, //权限列表
        status: m.status, //状态
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取指定角色参数
    _GetRoleInfo(id) {
      getRoleInfo({
        ko_id: id,
      })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.name = d.name; //角色名称
          m.sort = d.sort; //排序
          m.status = d.status; //状态
          m.notes = d.notes; //描述
          this.rightSelectList = Object.values(d.right_list); //权限列表
          this.loading = false;
        })
        .catch((err) => {
          this.isShow = false;
          this.$utils.error(err.err_msg);
        });
    },

    //获取所有权限列表
    _GetAllRightList() {
      getRightTable({
        pageNo: 1,
        pageSize: 999999,
      })
        .then((res) => {
          this.allRightList = res.data.data; //写入列表
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },

    /************** Tree底层横向排列用的 **************/

    //节点被展开时触发
    handleExpand() {
      //因为该函数执行在renderContent函数之前，所以得加this.$nextTick()
      this.$nextTick(() => {
        this.changeCss();
      });
    },

    //绘制节点内容
    renderContent(h, { node }) {
      //树节点的内容区的渲染 Function
      let classname = "";

      if (node.childNodes.length === 0) {
        classname = "foo";
      }

      return [
        h(
          "span",
          {
            class: classname,
          },
          node.label
        ),
      ];
    },

    //重绘CSS
    changeCss() {
      var levelName = document.getElementsByClassName("foo"); // levelname是上面的最底层节点的名字
      for (var i = 0; i < levelName.length; i++) {
        // cssFloat 兼容 ie6-8  styleFloat 兼容ie9及标准浏览器
        levelName[i].parentNode.style.cssFloat = "left"; // 最底层的节点，包括多选框和名字都让他左浮动
        levelName[i].parentNode.style.styleFloat = "left";
        levelName[i].parentNode.onmouseover = function () {
          this.style.backgroundColor = "#fff";
        };
      }
    },
  },
};
</script>

<style>
.el-tree--highlight-current > .el-tree-node > .el-tree-node__content {
  width: 100%;
}

.el-tree-node__content {
  width: 22%;
}
</style>
